import styled from '@emotion/styled';
import * as colors from '@/components/colors';
import { GatsbyLinkProps, Link } from 'gatsby';
import { AnchorHTMLAttributes, ElementType, ReactElement } from 'react';

type AsGatsbyLinkProps = { as: typeof Link } & GatsbyLinkProps<unknown>;
type AsAnchorProps = { as: undefined } & AnchorHTMLAttributes<unknown>;
type ButtonProps = {
  color?: string;
  outline?: boolean;
  shadow?: boolean;
  icon?: ReactElement;
  compact?: boolean;
  as?: ElementType<any>;
} & (Partial<AsAnchorProps> | Partial<AsGatsbyLinkProps>);

function lum(hex, lum) {
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, '');
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  lum = lum || 0;

  // convert to decimal and change luminosity
  var rgb = '#',
    c,
    i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += ('00' + c).substr(c.length);
  }

  return rgb;
}

const backgroundColor = {
  neutral: (props: ButtonProps) =>
    props.outline ? 'transparent' : props.color ? props.color : '#ffffff',
  hover: (props: ButtonProps) =>
    props.outline
      ? '#ffffff'
      : props.color
      ? lum(props.color, -0.1)
      : lum('#ffffff', -0.1),
};

const borderColor = {
  neutral: (props: ButtonProps) => (props.color ? props.color : '#ffffff'),
  hover: (props: ButtonProps) =>
    props.outline
      ? props.color
      : props.color
      ? lum(props.color, -0.1)
      : lum('#ffffff', -0.1),
};

const color = {
  neutral: (props) =>
    props.outline
      ? props.color
        ? props.color
        : '#ffffff'
      : props.color
      ? '#ffffff'
      : '#000000',
  hover: (props) =>
    props.outline
      ? props.color
        ? props.color
        : '#050505'
      : props.color
      ? '#ffffff'
      : '#000000',
};

const boxShadow = {
  neutral: (props: ButtonProps) =>
    props.shadow ? '0 2px 4px 0 rgba(0, 0, 0, 0.5)' : 'none',
};

const StyledButton = styled.a<ButtonProps>`
  border-radius: 4px;
  border: none;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  display: inline-flex;
  height: ${(props: ButtonProps) => (props.compact ? '32px' : '36px')};
  justify-content: center;
  align-items: center;
  /* align-self: flex-start; */
  padding: ${(props: ButtonProps) => (props.compact ? '0 10px' : '0 16px')};
  transition: 0.4s;
  cursor: pointer;

  border: 2px solid ${borderColor.neutral};
  color: ${color.neutral};
  background-color: ${backgroundColor.neutral};
  box-shadow: ${boxShadow.neutral};

  .icon {
    margin-right: 6px;
    display: inline-flex;
  }

  &:hover,
  &:focus {
    background-color: ${backgroundColor.hover};
    border-color: ${borderColor.hover};
    color: ${color.hover};
    transition: 0.4s;
    /* box-shadow: 0 2px 0px 0 ${colors.primaryDarker}; */
  }
`;

export const Button = (props: ButtonProps) => (
  <StyledButton {...props}>
    {props.icon && <span className="icon">{props.icon}</span>}
    {props.children}
  </StyledButton>
);
