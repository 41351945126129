import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from '@emotion/styled';

export const GalleryImg = styled.img`
  &:hover,
  &:active {
    box-shadow: 0 0 0 3px #ffffff;
  }
`;

const GalleryOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  z-index: 100;
  overflow: hidden;
  display: flex;
`;

const GalleryContent = styled.div`
  display: grid;
  grid-template-areas: 'close close' 'content content' 'left right';
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 48px minmax(50%, 1fr) 48px;
  grid-gap: 4px;
  padding: 4px;
`;

const GalleryModalClose = styled.button`
  grid-area: close;
  font-size: 18px;
  background: #333;
  color: #fff;
  border: none;
  border-radius: 8px;

  &:hover,
  &:active {
    background: #555;
  }
`;

const GalleryModalLeft = styled.button`
  grid-area: left;
  font-size: 24px;
  background: #333;
  color: #000;
  border: none;
  border-radius: 8px;

  &:disabled {
    display: none;
  }

  &:hover,
  &:active {
    background: #555;
  }
`;

const GalleryModalRight = styled.button`
  grid-area: right;
  font-size: 24px;
  background: #333;
  color: #fff;
  border: none;
  border-radius: 8px;

  &:disabled {
    display: none;
  }

  &:hover,
  &:active {
    background: #555;
  }
`;

const GalleryModalContent = styled.figure`
  grid-area: content;
  /* align-self: center; */
  margin: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

type GalleryModalProps = PropsWithChildren<{
  open: boolean;
  handleClose: () => void;
  prev: () => void | null;
  next: () => void | null;
}>;
export const GalleryModal = (props: GalleryModalProps): JSX.Element => {
  const { open, handleClose, children, prev, next } = props;

  if (!open) return null;

  return createPortal(
    <GalleryOverlay onClick={handleClose}>
      <GalleryContent onClick={(event) => event.stopPropagation()}>
        <GalleryModalClose onClick={handleClose}>Close</GalleryModalClose>
        <GalleryModalContent>{children}</GalleryModalContent>
        <GalleryModalLeft onClick={prev} disabled={prev === null}>
          ⬅️
        </GalleryModalLeft>
        <GalleryModalRight onClick={next} disabled={next === null}>
          ➡️
        </GalleryModalRight>
      </GalleryContent>
    </GalleryOverlay>,
    document.getElementById('portal')
  ) as unknown as JSX.Element;
};

interface Props {
  image: string;
  onOpen: () => void;
}

export const GalleryImage = (props: Props) => {
  const { onOpen } = props;

  return <GalleryImg src={props.image} onClick={onOpen} />;
};

type GalleryProps = { images: string[] };
export const Gallery = ({ images }: GalleryProps) => {
  const [open, setOpen] = useState(false);
  const [current, setCurrent] = useState(0);

  return (
    <>
      {images.map((image, index) => (
        <GalleryImage
          image={image}
          onOpen={() => {
            setOpen(true);
            setCurrent(index);
          }}
          key={index}
        />
      ))}

      <GalleryModal
        open={open}
        handleClose={() => setOpen(false)}
        prev={current > 0 ? () => setCurrent(current - 1) : null}
        next={
          current < images.length - 1 ? () => setCurrent(current + 1) : null
        }
      >
        <img src={images[current]} />
      </GalleryModal>
    </>
  );
};
