import React, { PropsWithChildren } from 'react';
import styled from '@emotion/styled';

export const GameHeroContainer = styled.section`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

export const GameLogo = styled.img`
  margin-bottom: 64px;
  width: 273px;
  height: 172px;
`;

export const GameHeroBackgroundVideo = styled.video`
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

interface GameHeroBackgroundProps {
  trailer: string;
}

export const GameHeroBackground = (props: GameHeroBackgroundProps) => (
  <GameHeroBackgroundVideo
    playsInline
    autoPlay
    muted
    loop
    disablePictureInPicture
    poster=""
  >
    <source src={props.trailer} type="video/mp4" />
  </GameHeroBackgroundVideo>
);

export const GameHeroContent = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px;
`;

type GameHeroProps = PropsWithChildren<unknown> & {
  backgroundTrailer: string;
};

export const GameHero = (props: GameHeroProps) => (
  <GameHeroContainer>
    <GameHeroContent>{props.children}</GameHeroContent>
    <GameHeroBackground trailer={props.backgroundTrailer} />
  </GameHeroContainer>
);
