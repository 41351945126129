export { default as logo } from './double-turn-logo.png';
export { default as screenshot } from './screenshots/6_highflyer-cage.png';

import { default as screenshot1 } from './screenshots/1_highflyer-aerial-2.png';
import { default as screenshot2 } from './screenshots/2_showman-powerbomb-2.png';
import { default as screenshot3 } from './screenshots/3_showman-flex.png';
import { default as screenshot4 } from './screenshots/4_match-card.png';
import { default as screenshot5 } from './screenshots/5_ref-count.png';
import { default as screenshot6 } from './screenshots/6_highflyer-cage.png';
import { default as screenshot7 } from './screenshots/7_striker-nightcap.png';
import { default as screenshot8 } from './screenshots/8_highflyer-hurrincanrana.png';
import { default as screenshot9 } from './screenshots/9_brawler-punch.png';

export const screenshots = [
  screenshot1,
  screenshot2,
  screenshot3,
  screenshot4,
  screenshot5,
  // screenshot6,
  screenshot7,
  screenshot8,
  screenshot9,
];
