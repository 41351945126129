import React, { PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet';
import styled from '@emotion/styled';

import { Button } from '@/components/Button';
import { itch, steam } from '@/images/icons';
import { logo, screenshots } from '@/images/double-turn';
import { trailer } from '@/images/double-turn/videos';
import { brands, primary } from '@/components/colors';
import { SiteFooter } from '@/components/SiteFooter';
import { SectionContent } from '@/components/layout/SectionContent';
import { SectionContainer } from '@/components/layout/SectionContainer';
import { size } from '@/components/mq';
import { GameHero, GameLogo } from '@/components/game/GameHero';
import { Section } from '@/components/layout/Section';
import { kg, rephil, orbSoundwerx } from '@/images';
import { TeamMember } from '@/components/TeamMember';
import { Gallery } from '@/components/Gallery';
import { Link } from 'gatsby';

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;

  > * {
    margin: 0 8px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;

const GameDetailsContent = styled(SectionContent)`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: auto;
  padding: 32px;
  align-items: center;
  color: #ffffff;

  ${size.large} {
    grid-template-columns: repeat(2, auto);
    padding: 32px 0;
  }

  h2 {
    font-family: 'Tommy Soft';
    font-size: 32px;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 16px;
  }

  p {
    margin-bottom: 16px;
    font-size: 18px;
  }
`;

const GameDetails = ({ children }: PropsWithChildren<unknown>) => (
  <SectionContainer>
    <GameDetailsContent>{children}</GameDetailsContent>
  </SectionContainer>
);

const YouTubeEmbed = styled.iframe`
  border-radius: 8px;
  max-width: 100%;
  max-height: calc((100vw - 64px) / (16 / 9));

  ${size.large} {
    max-width: none;
    max-height: none;
  }
`;

const GameMediaContainer = styled(SectionContainer)`
  background-color: ${primary};
  padding-top: 16px;
  padding-bottom: 16px;
`;

const GameMedia = styled.div`
  display: grid;
  grid-column-gap: 16px;
  grid-row-gap: 16px;

  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);

  ${size.large} {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  img {
    width: 100%;
    object-fit: cover;
    display: block;
    border-radius: 8px;
  }
`;

const TeamSection = styled(Section)`
  padding: 32px 16px;
  color: #fff;

  h2 {
    font-family: 'Tommy Soft';
    margin-top: 0;
    margin-bottom: 16px;
  }
`;

const TeamGrid = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fill, 356px);
`;

const DoubleTurnPage = () => (
  <>
    <main>
      <Helmet>
        <title>Double Turn</title>
      </Helmet>
      <GameHero backgroundTrailer={trailer}>
        <GameLogo src={logo} alt={'Double Turn'} />
        <ButtonRow>
          <Button
            href="https://store.steampowered.com/app/762680/Double_Turn/"
            icon={<img src={steam} width="24" height="24" />}
            color={brands.steam}
            shadow
          >
            Steam
          </Button>
          <Button
            href="https://inwave.itch.io/double-turn"
            icon={<img src={itch} width="24" height="24" />}
            color={brands.itch}
            shadow
          >
            itch.io
          </Button>
        </ButtonRow>
      </GameHero>
      <GameDetails>
        <YouTubeEmbed
          title="Double Turn trailer on YouTube"
          width="480"
          height="270"
          src="https://www.youtube.com/embed/ZNF3iyz4bVw?rel=0&amp;fs=0&amp;modestbranding=1"
          frameBorder="0"
          allow="encrypted-media"
          allowFullScreen
        />
        <div>
          <h2>Double Turn</h2>
          <p>
            Double Turn is a pro-wrestling party brawler with fast arcade
            fighting. Hop into the ring and master the simple melee combat in
            2-4 player local versus matches.
          </p>
          <p>
            The core combat is simple: strike, grab, throw, and block. Turn up
            the heat and charge up your signature move to takedown your opponent
            in style. The gameplay is quick to pick up and easy to learn.
          </p>
          <ButtonRow>
            <Button as={Link} to="/presskit">
              Presskit
            </Button>
            <Button as={Link} to="/patch-notes">
              Patch notes
            </Button>
          </ButtonRow>
        </div>
      </GameDetails>
      <GameMediaContainer>
        <SectionContent>
          <h2>Screenshots</h2>
          <GameMedia>
            <Gallery images={screenshots} />
          </GameMedia>
        </SectionContent>
      </GameMediaContainer>
      <TeamSection>
        <h2>Team</h2>
        <TeamGrid>
          <TeamMember
            name="Kenny Goff"
            role="Programmer, Designer"
            image={kg}
            link="https://kennygoff.com"
            linkText="kennygoff.com"
          />
          <TeamMember
            name="Phil Giarrusso"
            role="Art, Animation"
            image={rephil}
            link="https://twitter.com/rephildesign"
            linkText="@rephildesign"
          />
          <TeamMember
            name="Orb Soundwerx"
            role="Audio"
            image={orbSoundwerx}
            link="https://www.orbsoundwerx.com/"
            linkText="orbsoundwerx.com"
          />
        </TeamGrid>
      </TeamSection>
    </main>
    <SiteFooter />
  </>
);

export default DoubleTurnPage;
