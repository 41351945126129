import React from 'react';
import styled from '@emotion/styled';
import { primary } from './colors';

const Profile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

interface ProfileImageProps {
  size?: 'normal' | 'large';
}
const ProfileImage = styled.img`
  border-radius: ${({ size }: ProfileImageProps) =>
    size === 'large' ? '8px' : '8px'};
  margin-right: 16px;
`;

const Name = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: ${primary};
`;

const Role = styled.span`
  font-size: 18px;
  font-weight: bold;
`;

const Bio = styled.div`
  margin-top: 16px;
  font-size: 16px;
`;

const SocialIconLink = styled.a`
  display: inline-block;
  padding: 3px 0;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;

  img {
    margin-right: 4px;
  }

  &:hover,
  &:focus {
    color: #fff;
    text-decoration: underline;
  }

  &:hover img,
  &:focus img {
    filter: brightness(0) saturate(100%) invert(46%) sepia(73%) saturate(3509%)
      hue-rotate(191deg) brightness(101%) contrast(101%);
  }
`;

interface TeamMemberProps {
  size?: 'normal' | 'large';
  image: string;
  name: string;
  role: string;
  link?: string;
  linkText?: string;
}

export const TeamMember = (props: TeamMemberProps) => (
  <Profile>
    <ProfileImage
      src={props.image}
      alt={`${props.name}'s profile picture`}
      width={props.size === 'large' ? 150 : 100}
      height={props.size === 'large' ? 150 : 100}
      size={props.size}
    />
    <div>
      <div>
        <Name>{props.name}</Name>
      </div>
      <Role>{props.role}</Role>
      {props.link && (
        <div>
          <SocialIconLink href={props.link}>
            <span>{props.linkText}</span>
          </SocialIconLink>
        </div>
      )}
    </div>
  </Profile>
);
